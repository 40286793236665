import React, { useEffect, useState } from "react";
import {
  aws_url_employee_Image,
  currency,
  formatDate,
  getStaffLogin,
  getStatus,
  setGender,
  TOKEN,
} from "../../helper";
import Notiflix, { Loading } from "notiflix";
import BottomNav from "../../layouts/BottomNav";
import { useLazyQuery } from "@apollo/client";
import { EMPLOYEE_QUEY } from "./apollo";
import { LOGIN } from "../../routes/app";
import male from "../../img/male.png";
import female from "../../img/female.png";
import ChangePassword from "./changPassword";
export default function Profile() {
  const [reloadData, setReloadData] = useState(false);
  const [getUser, setGetUser] = useState("");
  const [fetchData, { data: dataStaff }] = useLazyQuery(EMPLOYEE_QUEY, {
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    const userId = getStaffLogin()?._id;
    if (!userId) return;
    fetchData({
      variables: {
        where: {
          _id: userId,
        },
      },
    });
  }, [fetchData, reloadData]);

  useEffect(() => {
    setGetUser(dataStaff?.users?.data[0]);
  }, [dataStaff]);

  const onLogout = () => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການອອກຈາກລະບົບແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      () => {
        Loading.dots();
        setTimeout(() => {
          Loading.remove();
          localStorage.clear();
          window.location.replace(LOGIN);
          localStorage.removeItem(TOKEN);
        }, 2000);
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div id="appCapsule">
        <div className="appHeader text-white border-0 ">
          <div style={{ flex: 1 }} className="text-left"></div>
          ໂປຣໄຟລ໌
          <div
            className="text-white pageTitle text-right text-nowrap pr-0 "
            style={{ flex: 1 }}
          >
            <button
              className="btn text-white ml-0"
              onClick={() => onLogout()}
              style={{ flex: 1, marginRight: -10 }}
            >
              <i className="icon-log-out1" style={{ fontSize: 25 }} />
            </button>
          </div>
        </div>
        <div className="section wallet-card-section" style={{ marginTop: -3 }}>
          <div
            className="wallet-card pt-5 text-center"
            style={{
              height: 200,
            }}
          >
            {getUser?.gender === "MALE" ? (
              <img
                src={
                  getUser?.profileImage
                    ? `${aws_url_employee_Image}/${getUser?.profileImage}`
                    : male
                }
                style={{
                  width: 100,
                  height: 90,
                  borderRadius: 4,
                  marginLeft: -10,
                }}
              />
            ) : (
              <img
                src={
                  getUser?.profileImage
                    ? `${aws_url_employee_Image}/${getUser?.profileImage}`
                    : female
                }
                style={{
                  width: 100,
                  height: 90,
                  borderRadius: 4,
                  marginLeft: -10,
                }}
              />
            )}{" "}
            <br />
            <b className="text-start text-danger">
              CVID: {getUser?.cvID ? getUser?.cvID : "-"}
              <br />
              ສິດທິ: {getUser?.role ? getStatus(getUser?.role) : "-"}
            </b>
          </div>

          <div className="session-list mt-3">
            <div className="text-end">
              <ChangePassword
                loadData={reloadData}
                getData={(data) => {
                  setReloadData(data);
                }}
                data={getUser}
              />
            </div>
            <div className="listview-title ml-0">ລາຍລະອຽດ</div>
            <>
              <ul className="listview image-listview text">
                <li>
                  <div className="item">
                    <div className="in">
                      <div>
                        ຊື່ ແລະ ນາມສະກຸນ <br />
                        ຊື່ພາສາອັງກິດ:
                      </div>
                      <div className="custom-control custom-switch user-select-all">
                        {getUser?.firstName ? getUser?.firstName : "-"}{" "}
                        {getUser?.lastName ? getUser?.lastName : "-"}
                        <br />
                        {getUser?.fullNameEng ? getUser?.fullNameEng : "-"}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ເພດ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {getUser?.gender ? setGender(getUser?.gender) : "-"}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ວັນເດືອນປີເກີດ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {formatDate(
                          getUser?.dateOfBirth ? getUser?.dateOfBirth : "-"
                        )}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ວັນທີ່ເລີ່ມວຽກ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {formatDate(
                          getUser?.workStartDate ? getUser?.workStartDate : "-"
                        )}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ເບີໂທ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {getUser?.phoneNumber ? getUser?.phoneNumber : "-"}
                        <br />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ຕຳແຫນ່ງ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {getUser?.position?.title_lao
                          ? getUser?.position?.title_lao
                          : "-"}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ພະແນກ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {getUser?.department?.title_lao
                          ? getUser?.department?.title_lao
                          : "-"}
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="item">
                    <div className="in">
                      <div>ຫນ່ວຍງານ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {getUser?.team?.title_lao
                          ? getUser?.team?.title_lao
                          : "-"}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ຊື່ບັນຊີ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {getUser?.bankAccountName
                          ? getUser?.bankAccountName
                          : "-"}
                        <br />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ເລກບັນຊີ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {getUser?.bankAccountNumber
                          ? getUser?.bankAccountNumber
                          : "-"}
                        <br />
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li>
                  <div className="item">
                    <div className="in">
                      <div>ເງິນເດືອນພື້ນຖານ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {currency(
                          getUser?.basicSalary ? getUser?.basicSalary : 0
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </li> */}
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ເງິນຕຳແຫນ່ງ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {currency(
                          getUser?.positionSalary ? getUser?.positionSalary : 0
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ເງິນອາກອນ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {getUser?.taxIncome ? currency(getUser?.taxIncome) : 0}{" "}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ເງິນປະກັນສັງຄົມ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {getUser?.InsuranceExpense
                          ? currency(getUser?.InsuranceExpense)
                          : 0}{" "}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ແຂວງ</div>
                      <div className="custom-control custom-switch">
                        {getUser?.province?.title
                          ? getUser?.province?.title
                          : "-"}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="item">
                    <div className="in">
                      <div>ເມືອງ</div>
                      <div className="custom-control custom-switch user-select-all">
                        {getUser?.district?.title
                          ? getUser?.district?.title
                          : "-"}
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="item">
                    <div className="in">
                      <div>ບ້ານ</div>
                      <div className="custom-control custom-switch">
                        {getUser?.village?.title
                          ? getUser?.village?.title
                          : "-"}
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <div className="item">
                    <div className="in">
                      <div>ອື່ນໆ</div>
                      <div className="custom-control custom-switch">
                        {getUser?.note ? getUser?.note : "-"}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </>
          </div>
        </div>
        <BottomNav />
      </div>
    </>
  );
}
